<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione Finanziarie</span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Elenco Finanziarie</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" @click="$refs.financialAddModal.open()">
            Aggiungi Finanziaria
          </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="financialProvider"
        :busy="loading"
        :filter="filter"
        :current-page="currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        striped
        show-empty
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle finanziarie...</strong>
          </div>
        </template>

        <template #empty>
            <h5 class="text-primary-light text-center my-2">Non ci sono finanziarie</h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">Non sono state trovate finanziarie</h5>
        </template>

        <template #cell(opzioni)="data">
          <feather-icon class="cursor-pointer" icon="EditIcon" size="20" @click="$refs.financialEditModal.open(data.item)" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
        </template>

      </b-table>

      <b-pagination
        v-if="financial.count > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="financial.count"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <!-- Modali -->

    <financial-add-modal ref="financialAddModal" @hide="getFinancial"/>
    <financial-edit-modal ref="financialEditModal"/>

  </section>
</template> 

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";

import { FinancialAddModal, FinancialEditModal } from "@/components/modals";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
    BPagination,
    FinancialAddModal,
    FinancialEditModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "financial_name",
        label: "Nome Finanziaria",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    sortBy: 'financial_name',
    sortDesc: false,
    financial: [],

    //Helpers

    perPage: 10,
    currentPage: 1,
    loading: false,
  }),

  methods: {
    async getFinancial(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getFinancial(request_data);
        this.financial = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async financialProvider(ctx) {
      await this.getFinancial();
      return this.financial.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
